import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, Container, Banner, ContactForm } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { H4, P } from '../styles/mixins';

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: file(relativePath: { eq: "assets/images/contact-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;

const ContactPage = (props) => {
  const { data } = props;
  const { windowWidth } = useWindowSize();
  const { site } = data || {};
  const { bannerImage } = data || {};
  const [selectedForm, setSelectedForm] = useState(0);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const banner = {
    caption: 'Contact Us',
    text: 'Contact us today to book your visit to our design studio',
    img: bannerImage
  };

  return (
    <main>
      <SEO
        title="Contact Us"
        description="We are located just off of Jamestown Road in Finglas Dublin 11. Please be aware that current visits to our deisng studio are by appointment only and must be arranged in advance."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Container>
        <Grid>
          <GridItem>
            <H4 id="get-in-touch" style={{ marginTop: 0 }}>
              Get In Touch
            </H4>
            <P>
              Thank you for your interest in working with Colin Maher Design on your project. If you
              wish to reach out and/or receive a quote, please start by filling out the form below
              and we will endeavour to get back to you as soon as possible.
            </P>

            <ContactForm />
          </GridItem>
          <GridItem>
            <H4>Design Studio Details</H4>
            <P>
              We are located just off of Jamestown Road in Finglas Dublin 11. Please be aware that
              current visits to our deisng studio are by appointment only and must be arranged in
              advance.
            </P>
            <H4>Contact Details</H4>
            <P>
              Phone: (01) 485 1176
              <br />
              Email: info@colinmaher.ie
            </P>
            <H4>Studio Location</H4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9515.578846348057!2d-6.2963459!3d53.3988233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dff16fd22763dc9!2sKitchenCraft!5e0!3m2!1sen!2sie!4v1614081892234!5m2!1sen!2sie"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="Contact Form"
            />
          </GridItem>
        </Grid>
      </Container>
    </main>
  );
};

const GridItem = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 3rem;
  }
`;

const Grid = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  padding: 3rem 1rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 3rem 0;
    width: 100%;
    max-width: 720px;
  }
  @media (min-width: 1200px) {
    padding: 4rem;
    max-width: 100%;
  }
`;

export default ContactPage;
